
<template>
  <div class="roles_box">
    <div>
      <!-- 欢迎使用 -->
      <div class="main22 main_background">
        <!-- <p id="punish_p">聚焦优质的监管信息，捕获深度犀利的合规资讯</p>
        <p>在合规数据库，不错过银行保险业的每个重要分析解读时刻</p> -->
        <p id="punish_p">合规资讯</p>
        <p>合规热点，专业解读</p>
        <div class="punish_select">
          <el-select v-model="value" placeholder="最新" @change="yearChange">
            <el-option
              v-for="(item, index) in yearInfo"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 内容 -->
    </div>
    <div class="punish_detail_box">
      <!-- 列表 -->
      <div class="punish_detail">
        <div class="punish_detail_header">
          <!-- <img src="@/assets/image/role/rolesIcon.png1.png" alt="" /> -->
          <img src="@/assets/image/compliance/complianceIcon.svg" alt="" />
          <span>合规资讯</span>
        </div>
        <ul class="punish_detail_list">
          <li
            v-for="(item, index) in rolesList"
            :key="index"
            @click="goDetail(item)"
          >
            <span>{{ index + 1 }}</span>
            <span>{{ item.name }}
              <a v-if="item.fileNum != null && item.fileNum != '' && item.type != 3">
              （{{ item.fileNum }}）
              </a>
            </span>
            <span>{{ item.publishTime }}</span>
          </li>
        </ul>
        <!-- 分页 -->
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="prev,pager,next,sizes,jumper"
            :total="total"
            :pager-count="9"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDutyListApi } from "@/api/dutyListApi";
export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "2",
      list: [],
      value: "",
      year: "",
      yearInfo: [],
      rolesList: [],
      total: 0,
    };
  },
  async mounted() {
    this.id = this.$route.query.id;
    await getDutyListApi({
      type: 4,
    }).then((res) => {
      this.yearInfo = res.data.data.yearInfo;
      this.rolesList = res.data.data.regulation;
      this.total = res.data.data.total;
    });
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
  },
  methods: {
    //合规资讯详情
    goDetail(item) {
      let id = item.id
      let type = item.type
      if(type == 3){
        window.open(item.fileNum,"_blank")
      }else{
        this.$router.push({ name: "compliance/article", query: { id,type } });
      }
    },
    async yearChange(item) {
      this.year = item;
      await getDutyListApi({
        type: 4,
        yearType: this.year,
      }).then((res) => {
        this.rolesList = res.data.data.regulation;
        this.total = res.data.data.total;
      });
    },
    async handleSizeChange(val) {
      await getDutyListApi({
        type: 4,
        yearType: this.year,
        pageSize: val,
      }).then((res) => {
        this.rolesList = res.data.data.regulation;
      });
    },
    async handleCurrentChange(val) {
      await getDutyListApi({
        type: 4,
        yearType: this.year,
        pageNum: val,
      }).then((res) => {
        this.rolesList = res.data.data.regulation;
      });
    },
  },
};
</script>
<style scoped>
@import "../../static/css/page.css";
@import "../../static/css/headerBanner.css";

.punish_detail {
  height: 550px;
}
.main_background {
  background: url("../../assets/image/compliance/complianceBanner1.jpg")
    no-repeat;
  background-size: 100% 100%;
}
</style>
<style lang="scss" >
</style>