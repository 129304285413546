
<template>
  <div class="background_block">
    <div class="dutyList_detail">
      <!-- 头部 -->
      <div class="dutyList_detail_header">
        <img src="@/assets/image/dutyList/义务清单icon.png" alt="" />
        <span>{{ listname }}</span>
        <!--<button @click="download">下载</button>-->
      </div>
      <!-- 列表 -->
      <ul class="dutyList_detail_list">
        <li v-for="(item, index) in dutyList" :key="index">
          <!-- <span>{{index==10?index + 1:index}} </span> -->
          <!-- <span>{{ index + 1 }} </span> -->
          <!-- <span>{{ index + 1 !== "" ? 0 : "" }}{{ index + 1 }} </span> -->
          <!-- <span>·</span> -->
          <span v-html="item.contentInfo" class="ql-editor">{{
            item.contentInfo
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getDutyDetailApi, dutyListDownloadApi } from "@/api/dutyDetailApi";
export default {
  data() {
    return {
      dutyList: [],
      id: "",
      listname: "",
    };
  },
  async mounted() {
    this.id = this.$route.query.id;
    await getDutyDetailApi({
      params: {
        obliId: this.id,
      },
    }).then((ret) => {
      this.dutyList = ret.data.data.content;
      this.listname = ret.data.data.name;
    });
  },
  methods: {
    // 下载文档
    async download() {
      await dutyListDownloadApi(
        {
          obliId: this.id,
        },
        { responseType: "blob" }
      ).then((ret) => {
        let blob = new Blob([ret.data], { type: "application/pdf" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        // downloadElement.download = `用户手册-${this.name}`    //下载后的文件名，根据需求定义
        downloadElement.download = this.listname; //下载后的文件名，根据需求定义
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      });
    },
  },
};
</script>
<style scoped>
.background_block {
  background-image: url("../../../assets/image/dutyList/qingdan.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  width: 102%;
  height: 96%;
}
</style>
<style lang="scss">
.dutyList_detail {
  width: 64%;
  height: 97%;
  margin: 32px auto;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}
.dutyList_detail_header {
  display: flex;
  margin: 0 0 20px 20px;
  padding-top: 28px;
}
.dutyList_detail_header > img {
  width: 24px;
  height: 24px;
  margin: 4px 12px 0 0;
}
.dutyList_detail_header > span {
  color: rgba(0, 0, 0, 1);
  font-weight: 900;
  font-size: 20px;
  font-family: PingFangSC-Semibold;
  line-height: 32px;
}
.dutyList_detail_header > button {
  width: 65px;
  height: 32px;
  border-radius: 2px;
  background-color: rgba(24, 144, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  margin: 0 32px 0 auto;
  color: white;
  letter-spacing: 3px;
  border: 0;
  cursor: pointer;
}
.dutyList_detail_list {
  // margin-left: 20px;
  padding: 0 20px;
}
.dutyList_detail_list > li {
  list-style: none;
  // font-size: 16px;
  // margin-bottom: 10px;
  // margin-right: 30px;
  // line-height: 26px;
}
// .dutyList_detail_list > li span:first-child {
//   /* font-weight: 900; */
// }
// .dutyList_detail_list > li span:nth-child(2) {
//   margin: 0 3px;
// }
// .dutyList_detail_list > li span:nth-child(3) {
//   margin: 0 3px;
//   color: rgb(109, 104, 104);
// }
</style>