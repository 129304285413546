
<template>
  <!-- 导航栏 -->
  <div class="punish_detail_box">
    <div class="punish_detail_banner">
      <ul>
        <li
          v-for="(item, index) in bannerPunish"
          :key="index"
          :class="tabIndex === index ? 'tabActive' : ''"
          @click="tab(index)"
        >
          {{ item.name }}
          <div
            class="punishLine"
            :class="tabIndex === index ? 'clickPunishLine' : ''"
          ></div>
        </li>
      </ul>
      <div class="punish_select">
        <el-select
          v-model="value"
          :placeholder="year == 0 ? yearInfo[0] : year"
          @change="yearChange"
        >
          <el-option
            v-for="(item, index) in yearInfo"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 列表 -->
    <div class="punish_detail">
      <div class="punish_detail_header">
        <img src="@/assets/image/dutyList/义务清单icon.png" alt="" />
        <span>{{
          level == 1 ? "中国银保监会" : level == 2 ? "银保监局" : "银保监分局"
        }}</span>
      </div>
      <!-- 列表 -->
      <ul class="punish_detail_list">
        <li
          v-for="item in morePunish"
          :key="item.id"
          @click="goArticle(item.id)"
        >
          <span>{{ item.num }}</span>
          <span>{{ item.name }}（{{ item.fileName }}）</span>
          <span>{{ item.punTime }}</span>
        </li>
      </ul>
      <!-- 分页 -->
      <div>
        <!-- :current-page="currentPage" -->

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="1"
          layout="prev,pager,next,sizes,jumper"
          :total="total"
          background
          :pager-count="9"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { morePunishApi } from "@/api/morePunishApi";
export default {
  data() {
    return {
      morePunish: [],
      tagDetIdList: [],
      nameList: [],
      punTimeType: "",
      punTimeBeg: "",
      punTimeEnd: "",
      bannerPunish: [
        { level: 1, name: "中国银保监会" },
        { level: 2, name: "银保监局" },
        { level: 3, name: "银保监分局" },
      ],
      tabIndex: "",
      level: "",
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      total: "",
      value: "",
      yearInfo: "",
      year: "",
      punName: "",
    };
  },
  methods: {
    async handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      let ret = await morePunishApi({
        punishType: this.level,
        pageSize: val,
        yearType: this.year,
      });
      if (ret.data.code === 200) {
        this.morePunish = ret.data.data.rows;
      }
    },
    async handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      let ret = await morePunishApi({
        punishType: this.level,
        pageNum: val,
        yearType: this.year,
      });
      if (ret.data.code === 200) {
        this.morePunish = ret.data.data.rows;
      }
    },
    goArticle(id) {
      this.$router.push({ name: "article", query: { id } }); // 只能用 name
    },
    //切换年份
    async yearChange(item) {
      this.year = item;
      let ret = await morePunishApi({
        punishType: this.level,
        yearType: item == "最新" ? "" : item,
      });
      if (ret.data.code === 200) {
        this.morePunish = ret.data.data.rows;
        this.total = ret.data.data.total;
      }
    },
    async tab(index) {
      this.tabIndex = index;
      this.level = index + 1;
      let ret = await morePunishApi({
        punishType: index + 1,
        yearType: this.year,
      });
      if (ret.data.code === 200) {
        this.morePunish = ret.data.data.rows;
        this.total = ret.data.data.total;
      }
    },
  },
  async mounted() {
    console.log(localStorage.getItem("punishQuery"));
    let para = JSON.parse(localStorage.getItem("punishQuery"));
    this.level = para.level;
    this.yearInfo = para.yearInfo;
    this.tabIndex = this.level - 1;
    this.year = para.year;
    let ret = await morePunishApi({
      punishType: this.level,
      yearType: this.year,
    });
    if (ret.data.code === 200) {
      this.morePunish = ret.data.data.rows;
      this.total = ret.data.data.total;
    }
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
  },
};
</script>
<style lang="scss" scoped>
@import "../../../static/css/page.css";
.punish_detail {
  height: 83%;
  max-height: 83%;
}
.tabActive {
  font-weight: 900;
}
.punishLine {
  width: 40px;
  height: 2px;
  background-color: transparent;
  margin: 0 auto;
  position: relative;
  top: -6px;
}
.clickPunishLine {
  background-color: white;
}
.punish_detail_banner {
  height: 56px;
  background: linear-gradient(90deg, #2468f2 0%, #008dff 100%);
}
.punish_detail_box {
  height: 100%;
}
.punish_detail_banner > ul {
  float: left;
  display: flex;
  line-height: 56px;
  list-style: none;
  text-align: center;
  margin-left: 33px;
}
.punish_detail_banner > ul > li {
  color: white;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
}
.punish_select {
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  float: right;
  margin-top: 12px;
  margin-right: 32px;
}
.lineActive {
  border-bottom: 1px solid #f9fafc;
  margin-top: 50px !important;
  padding-bottom: 10px;
}
// .el-select--mini {
//   width: 0;
//   border: 0 !important;
// }
</style>