<template>
  <div class="background_block">
    <div class="punish_article dutyList_detail">
      <!-- 标题 -->
      <div v-if="this.$route.query.type == 1 || this.$route.query.type == 3" class="punish_article_title">
        <h3>{{ articleList.name }}（{{ articleList.fileNum }}）</h3>
        <span>发布时间：{{ articleList.publishTime }}</span>
        <span>来源：{{ articleList.resource }}</span>
        <p></p>
      </div>
      <div v-else class="punish_article_title">
        <h3>{{ articleList.name }}（{{ articleList.fileNum }}）</h3>
        <span>发布时间：{{ articleList.govPubTime }}</span>
        <span>来源：{{ articleList.punOrg }}</span>
        <p></p>
      </div>
      <!-- 列表 -->
      <div class="punish_article_content">
        <div>
          <span class="paragraph1" v-html="articleList.content">

          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getrolesDetailApi } from "@/api/rolesDetailApi";
import { getPunishArticleApi } from "@/api/punishArticleApi";
import { getSubionsArticleApi } from "@/api/subionsArticleApi";
export default {
  data() {
    return {
      articleList: [],
    };
  },
  async mounted() {

    if(this.$route.query.type == 1){
      await getrolesDetailApi({
        params: {
          reguId: this.$route.query.id,
        },
      }).then((res) => {
        console.log(res.data.data);
        this.articleList = res.data.data;
      });
    }else if (this.$route.query.type == 2){
      let ret = await getPunishArticleApi({
        params: {
          punId: this.$route.query.id,
        },
      });
      if (ret.data.code == 200) {
        this.articleList = ret.data.data;
        console.log(this.articleList);
      }
    }else{
      let ret = await getSubionsArticleApi({
        params: {
          subionsId: this.$route.query.id,
        },
      });
      if (ret.data.code == 200) {
        this.articleList = ret.data.data;
        console.log(this.articleList);
      }
    }


  },
};
</script>
<style scoped>
@import "../../../static/css/article.css";
</style>
