
<template>
  <div class="background_block">
    <div class="punish_article dutyList_detail">
      <!-- 标题 -->
      <div class="punish_article_title">
        <h3>{{ articleList.name }}（{{ articleList.fileNum }}）</h3>
        <span>发布时间：{{ articleList.govPubTime }}</span>
<!--        <span>来源：{{ articleList.punOrg }}</span>-->
        <p></p>
      </div>
      <!-- 列表 -->
      <div class="punish_article_content">
        <div>
          <span class="paragraph1" v-html="articleList.content">
            <!-- {{articleList}} -->
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPunishArticleApi } from "@/api/punishArticleApi";
export default {
  data() {
    return {
      articleList: [],
    };
  },
  async mounted() {
    let ret = await getPunishArticleApi({
      params: {
        punId: this.$route.query.id,
      },
    });
    if (ret.data.code == 200) {
      this.articleList = ret.data.data;
      console.log(this.articleList);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../static/css/article.css";
</style>